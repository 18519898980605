import React ,{ Component } from 'react';
import { graphql } from "gatsby"
import Layout  from '../components/layout.js';
import Seo from "../components/seo"
import Hero  from '../components/hero.js';
import HeroTitle  from '../components/hero-title/hero-title';




class LeadershipSingleTemplate extends Component {
	render () {
		const source = this.props.data.wpLeadershipMember ? this.props.data.wpLeadershipMember : this.props.data.wpNewsArticle
		const widget = this.props.data.wp.themeGeneralSettings.singlePagesRelations.singlePagesRelations
		const currentPage = this.props.data.wpPage

		return (
			<Layout>
				<Seo
					title={source.title}
					contentBody={source.content}
					locationOrigin={this.props.location.origin + source.uri}
					domen={this.props.location.origin}
					image={source.featuredImage ?  source.featuredImage.node.sourceUrl.replace(process.env.WP_SITE_URL,"/") : null}
				/>
				<div>
					<Hero videoId={this.props.data.wpPage.headerBackground.vimeoId} headerBackground={this.props.data.wpPage.headerBackground}/>
					{currentPage?.title &&
						<HeroTitle title={currentPage?.title} />
					}
					<section className="main-content">
						<div className="container">
							<div className="uk-grid" data-uk-grid>
								<div className="uk-width-2-3@m">
									<h2>{source.title}</h2>
									<div dangerouslySetInnerHTML={{__html: source.content}} />
								</div>
								<div className="widget uk-width-1-3@m">
										{currentPage.slug ===  "newsroom"  ? widget.newsSingles.map((news,index) =>(
											<div className="operations-box" dangerouslySetInnerHTML={{__html: news.content}} key={index} />
										)): widget.leadershipSingles.map((single,index) => (
											<div className="operations-box" dangerouslySetInnerHTML={{__html: single.content}} key={index} />
										))}
								</div>
							</div>
						</div>
					</section>
				</div>
			</Layout>
		)
	}
}

export default LeadershipSingleTemplate;


export const data = graphql`
	query($id: String!,$pageId:String!) {
		wpPage(id: { eq: $pageId }) {
			uri
			title
			slug
			status
			content
			featuredImage {
				node {
					sourceUrl
				}
			}
			headerBackground {
				vimeoId
				videoPoster {
					sourceUrl
				}
			}
			wpParent {
				node {
					slug
				}
			}
		}
		wpLeadershipMember(id: { eq: $id }) {
			slug
			title
			content
			uri
		}
		wpNewsArticle(id: { eq: $id }) {
			slug
			title
			content
			uri
		}
		wp {
			themeGeneralSettings {
				singlePagesRelations {
					singlePagesRelations {
						newsSingles {
							... on WpSidebarWidgets {
								id
								content
								title
								slug
							}
						}
						leadershipSingles {
							... on WpSidebarWidgets {
								id
								title
								content
								slug
							}
						}
					}
				}
			}
		}
		wpMenu(name: {eq: "About-submenu"}) {
			menuItems {
				nodes {
					url
					label
				}
			}
		}
	}
`